
import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import ReactDOM from 'react-dom/client';
import { Toaster } from 'react-hot-toast';

import './assets/style.css';

const Login = lazy(()=>import('./pages/login/index'));

const App = lazy(()=>import('./pages/app/index'));

const Color = lazy(()=>import('./pages/app/color/index'));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Toaster/>
    <Router>
      <Suspense fallback={
        <div className="w-full flex items-center justify-center" style={{ minHeight: '100vh' }}>
          <span className="loader black-loader"></span>
        </div>
      }>
        <Routes>
          <Route path='/' element={<App/>}>
            <Route path='color' element={<Color/>}/>
          </Route>
        </Routes>
      </Suspense>
    </Router>
  </>
);